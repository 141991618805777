import { store } from "@/store/store";
import { empresa } from "@/shared/dtos/empresa";
import {
  VuexModule,
  Mutation,
  getModule,
  Module,
  Action,
} from "vuex-module-decorators";
import { ssmHttpService } from "@/shared/services/http-service";
import { API } from "@/shared/api";

@Module({
  namespaced: true,
  name: "empresaModule",
  store,
  dynamic: true,
})
class empresaModule extends VuexModule {
  public empresas: empresa[] = [];
  public empresa: empresa = new empresa();

  @Action({ commit: "onGetempresas" })
  public async getempresas() {
    return await ssmHttpService.get(API.empresas);
  }

  @Action({ commit: "onGetempresa" })
  public async getempresa(id: any) {
    return await ssmHttpService.get(API.empresas + "/" + id);
  }

  @Action
  public async guardarempresa(empresa: empresa) {
    /*toJson() no existe pero existe en la extends de BaseDto*/
    await ssmHttpService.post(API.empresas, empresa.toJson());
    this.getempresas();
  }

  @Action
  public async modificarempresa(empresa: empresa) {
    await ssmHttpService.put(API.empresas + "/" + empresa.id, empresa);
    this.getempresas();
  }

  @Action
  public async eliminarempresa(empresa: empresa) {
    await ssmHttpService.delete(API.empresas + "/" + empresa.id, null, false);
    this.getempresas();
  }

  @Mutation
  public onGetempresas(res: empresa[]) {
    this.empresas = res ? res.map((x) => new empresa(x)) : [];
  }

  @Mutation
  public onGetempresa(res: empresa) {
    this.empresa = new empresa(res);
  }
}
export default getModule(empresaModule);
