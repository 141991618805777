



















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import empresaModule from "@/store/modules/empresa-module";
import { empresa } from "@/shared/dtos/empresa";

@Component({
  components: {},
})
export default class EmpresasFormulario extends Mixins(HelperDinamicallyForm) {
  public item_pantalla: empresa = new empresa();

  public async OnCreated() {
    if (this.getparamId().HayParametro) {
      await empresaModule.getempresa(this.getparamId().Parametro);
      this.item_pantalla = empresaModule.empresa;
    }
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      60
    )
      .isRequired()
      .Dimesiones(12, 10, 6);
  }

  public Insertar(object: empresa) {
    empresaModule.guardarempresa(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: empresa) {
    empresaModule.modificarempresa(object).then(() => {
      this.AllSaveOk();
    });
  }
}
